import * as React from "react";
import { navigate } from "gatsby-link";
import Layout from "../../components/Layout";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
  };

  render() {
    return (
      <Layout
        pageTitle="Contact Us | Subscribe Sense" 
        pageDescription="Send us your questions, we're always happy to answer!"
      >
        <section className="section">
          <div className="container">
            <div className="relative bg-white">
              <div className="absolute inset-0">
                <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50"></div>
              </div>
              <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
                <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
                  <div className="max-w-lg mx-auto">
                    <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
                      Get in touch
                    </h2>
                    <p className="mt-3 text-lg leading-6 text-gray-500">
                      We're always excited to talk to people about what we're building!
                    </p>
                  </div>
                </div>
                <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
                  <div className="max-w-lg mx-auto lg:max-w-none">
                    <form className="grid grid-cols-1 gap-y-6"
                      name="contact"
                      method="post"
                      action="/contact/thanks/"
                      data-netlify="true"
                      data-netlify-honeypot="bot-field"
                      onSubmit={this.handleSubmit}
                    >
                        <input type="hidden" name="form-name" value="contact" />
                        <div hidden>
                          <label>
                            Don’t fill this out:{" "}
                            <input name="bot-field" onChange={this.handleChange} />
                          </label>
                        </div>
                        <div className="field">
                          <label htmlFor={"name"} className="label sr-only">
                            Your name
                          </label>
                          <div className="control">
                            <input
                              type={"text"}
                              name={"name"}
                              onChange={this.handleChange}
                              id={"name"}
                              required={true}
                              placeholder="Full name"
                              className="input block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                            />
                          </div>
                        </div>

                        <div className="field">
                          <label className="label sr-only" htmlFor={"email"}>
                            Email
                          </label>
                          <div className="control">
                            <input
                              className="input block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                              type={"email"}
                              name={"email"}
                              onChange={this.handleChange}
                              id={"email"}
                              required={true}
                              placeholder="Email"
                            />
                          </div>
                        </div>                      
                      
                      <div className="field">
                        <label htmlFor={"message"} className="sr-only">
                          Message
                        </label>
                        <textarea
                          name={"message"}
                          onChange={this.handleChange}
                          id={"message"}
                          required={true}
                          rows={4}
                          className="textarea block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
                          placeholder="Message"
                          defaultValue={''}
                        />
                      </div>
                      <div className="field">
                        <button
                          type="submit"
                          className="button is-link inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>            
          </div>
        </section>
      </Layout>
    );
  }
}
